import { Grid, Container, Typography, Box, Paper, Divider, TextField, Button, Snackbar, Alert } from "@mui/material";
import LOGO from "./logo.png"
import { useState } from "react";
export default function App() {
  const [ShowLoading, isShowLoading] = useState(false)
  const [ShowSnack, isShowSnack] = useState(false)
  const [SnackBarMessage, isSnackBarMessage] = useState("")
  const [SnackBarType, isSnackBarType] = useState("")
  const ChangeSnack = () => {
    isShowSnack(!ShowSnack)
  }
  const DoApprove = (event) => {
    event.preventDefault();
    isShowLoading(true)
    const data = new FormData(event.currentTarget);
    fetch("https://trainingapi.my-soc.org/submit.php", {
      method: "post",
      body: JSON.stringify({
        ref: data.get("ref"),
        fn: data.get("fn"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          isSnackBarMessage(item.mes);
          isShowLoading(false)
          if (item.id === 1) {
            isSnackBarType("success");
            ChangeSnack()
          } else {
            isSnackBarType("error");
            ChangeSnack()
          }
        });
      });
    event.currentTarget.reset()
  }
  return (
    <Container maxWidth="lg">
      <Grid container spacing={2} sx={{ py: 2 }}>
        <Grid item lg={3} md={6} sm={6}>
          <img src={LOGO} alt="StarsOrbit" className="LoginImage" />
        </Grid>
        <Grid item lg={9} md={6} sm={6}></Grid>
      </Grid>
      <Grid container spacing={2} sx={{ py: 2 }}>
        <Grid item lg={12} md={12} sm={12}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Box component="form" onSubmit={DoApprove}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Provide Your Information
              </Typography>
              <Divider />
              <Grid
                container
                spacing={1}
                sx={{
                  mt: 1,
                  mb: 2,
                }}
              >
                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ py: 2 }}>
                  <TextField
                    size="small"
                    fullWidth
                    id="ref"
                    name="ref"
                    required
                    autoFocus
                    label="SOC Referrence"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ py: 2 }}>
                  <TextField
                    size="small"
                    fullWidth
                    id="fn"
                    name="fn"
                    required
                    autoFocus
                    label="Full Name"
                  />
                </Grid>
              </Grid>
              <Divider />
              <Box sx={{ textAlign: "end", my: 2 }}>
                <Button
                  variant="contained"
                  type="submit"
                  fullWidth
                  size="small"
                  color="primary"
                >
                  Submit Information
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      {ShowLoading && (<div className="submitLoader">
        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>)}
      {ChangeSnack && (<Snackbar
        autoHideDuration={3000}
        open={ShowSnack}
        onClose={ChangeSnack}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={ChangeSnack}
          variant="filled"
          severity={SnackBarType}
          sx={{ width: "100%" }}
        >
          {SnackBarMessage}
        </Alert>
      </Snackbar>)}
    </Container >
  );
}